import { Card as AntCard } from 'antd'
import { colors } from 'src/common/constants'

const Card = props => {
  const { children, title } = props
  return (
    <AntCard
      size={'small'}
      headStyle={{
        backgroundColor: colors.main,
        color: colors.white,
        textTransform: 'uppercase'
      }}
      bodyStyle={{ textAlign: 'center' }}
      style={{ height: '100%' }}
      title={title}>
      {children}
    </AntCard>
  )
}
export default Card
