import { Modal, Select } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

const StoreModal = props => {
  const { intl, isVisible, onCloseModal, onSelectStore, stores } = props
  return (
    <Modal
      centered
      visible={isVisible}
      title={intl.formatMessage({ id: 'storeModal.title' })}
      footer={false}
      onCancel={onCloseModal}>
      <Select
        showSearch
        optionFilterProp={'children'}
        placeholder={intl.formatMessage({ id: 'storeModal.title' })}
        onChange={onSelectStore}
        size={'large'}
        style={{ width: '100%' }}>
        {stores.map(store => (
          <Select.Option key={store.id}>{store.name}</Select.Option>
        ))}
      </Select>
    </Modal>
  )
}
StoreModal.propTypes = {
  isVisible: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSelectStore: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object),
}
export default injectIntl(StoreModal)
