import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import { Result } from 'antd'
import { connect } from 'react-redux'

import { getStores } from 'src/redux-store/actions/app-actions'
import { updateStoreId } from 'src/redux-store/actions/user-actions'

import Auth from '../Auth'
import Home from '../Home'
import Page404 from '../404'
import Header from '../../components/Header'
import StoreModal from 'src/components/StoreModal'
import { Layout } from '../../common/styled'

const App = props => {
  const { intl, user, stores, getStores, updateStoreIdAction } = props
  const [isStoreModalVisible, setIsStoreModalVisible] = useState(false)

  const isEmptyUser = isEmpty(user)

  useEffect(() => {
    if (user.isAdmin) {
      getStores()
      openStoreModal()
    }
  }, [isEmptyUser, user.isAdmin, getStores])

  const updateStoreId = storeId => {
    updateStoreIdAction(storeId)
    closeStoreModal()
  }

  const openStoreModal = () => setIsStoreModalVisible(true)
  const closeStoreModal = () => setIsStoreModalVisible(false)

  const renderUserIsNotEnabled = () => {
    return (
      <Result
        status={'403'}
        title={intl.formatMessage({ id: 'app.user.isNotEnabled' })}
        style={{ height: 'calc(100vh - 80px)' }}
      />
    )
  }

  const renderHealth = useCallback(() => {
    return <Result status={'success'} title={intl.formatMessage({ id: 'app.healthCheck' })} />
  }, [intl])

  return (
    <Layout>
      <Header user={user} changeStore={openStoreModal} />
      <Auth>
        <Switch>
          {!user.isEnabled ? (
            <Route render={() => renderUserIsNotEnabled()} />
          ) : null}
          <Route component={Home} exact path={'/'} />
          <Route render={renderHealth} exact path={'/health'} />
          <Route component={Page404} />
        </Switch>
      </Auth>
      <StoreModal
        stores={stores}
        isVisible={isStoreModalVisible}
        onCloseModal={closeStoreModal}
        onSelectStore={updateStoreId}
      />
    </Layout>
  )
}
App.propTypes = {
  getStores: PropTypes.func,
  stores: PropTypes.arrayOf(PropTypes.object),
  updateStoreId: PropTypes.func,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.user,
  stores: state.app.stores
})
const mapDispatchToProps = {
  getStores,
  updateStoreIdAction: updateStoreId
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App))
