import { useCallback, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'

import { EditableFieldWrapper, SmallInput } from './styled'

const EditableField = props => {
  const { intl, name, data, onInputChange, onOk } = props
  const [isEditing, setIsEditing] = useState(false)

  const onOkCallback = useCallback(() => {
    setIsEditing(false)
    onOk()
  }, [onOk])

  return (
    <EditableFieldWrapper>
      <FormattedMessage id={`editableField.label.${name}`} />
      {isEditing ? (
        <>
          <SmallInput
            id={name}
            placeholder={intl.formatMessage({
              id: `input.placeholder.${name}`
            })}
            defaultValue={data}
            onChange={e => onInputChange(e.target.value)}
          />
          <CheckOutlined onClick={onOkCallback} />
        </>
      ) : (
        <>
          <strong>{data}</strong>
          <EditOutlined onClick={() => setIsEditing(true)} />
        </>
      )}
    </EditableFieldWrapper>
  )
}
export default injectIntl(EditableField)
