import { injectIntl } from 'react-intl'
import { Col, List, Row } from 'antd'
import Card from 'src/components/Card'

const GridStockDeliveries = props => {
  const { intl, product } = props
  const { stocks, deliveries } = product
  return (
    <Row gutter={[16, 16]} style={{ padding: 16 }}>
      <Col span={12}>
        <Card title={intl.formatMessage({ id: 'card.title.storeStock' })}>
          {stocks.store || '—'}
        </Card>
      </Col>
      <Col span={12}></Col>
      {deliveries.map(delivery => (
        <Col span={24} key={delivery.key}>
          <Card title={intl.formatMessage({ id: `card.title.${delivery.key}` })}>
            <List size={'small'} style={{ textAlign: 'left' }}>
              <List.Item>
                <List.Item.Meta
                  title={delivery.date}
                />
                <span>
                  {`${delivery.price.toFixed(delivery.price % 1 && 2)}€`}
                </span>
              </List.Item>
            </List>
          </Card>
        </Col>
      ))}
    </Row>
  )
}
export default injectIntl(GridStockDeliveries)
