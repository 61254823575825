import styled from 'styled-components'

export const BarcodeScannerContainer = styled.div`
  height: calc(100vh - 450px);
  @media screen and (orientation: landscape) {
    height: calc(100vw - 450px);
  }
  & .scandit-container {
    height: calc(100vh - 450px);
    @media screen and (orientation: landscape) {
      height: calc(100vw - 450px);
    }
    & .scandit-barcode-picker {
      max-width: 100% !important;
      max-height: 100% !important;
      & video {
        object-fit: cover !important;
      }
    }
  }
`
