import styled from 'styled-components'
import { Layout } from 'antd'

import { colors } from '../../common/constants'

export const HeaderBar = styled.div`
  height: 16px;
  background: ${colors.main};
`
export const StyledHeader = styled(Layout.Header)`
  background: ${colors.white};
  border: 1px solid ${colors.grey2};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 0 16px;
  z-index: 999;
`