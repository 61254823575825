import PropTypes from 'prop-types'
import { Loading3QuartersOutlined } from '@ant-design/icons'

import { StyledLoader } from './styled'

const Loader = props => {
  const { height } = props
  return (
    <StyledLoader
      height={height}
      indicator={<Loading3QuartersOutlined spin />}
    />
  )
}
Loader.propTypes = {
  height: PropTypes.string,
}
export default Loader
