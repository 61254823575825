import { Input } from 'antd'
import styled from 'styled-components'

export const EditableFieldWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const SmallInput = styled(Input)`
  width: 64px;
`
