import styled from 'styled-components'
import { colors } from 'src/common/constants'

export const ProductContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 104px 1fr;
`

export const ProductTitle = styled.h3`
  font-weight: bold;
  color: ${colors.grey4};
  margin-bottom: 8px;
`
export const ProductDescription = styled.span`
  color: ${colors.grey4};
`
