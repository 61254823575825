import {
  GET_ORDER_TRACKING,
  GET_SCAN_DETAILS,
  GET_STORES,
  RESET_ORDER_TRACKING,
  RESET_SCAN_DETAILS
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    return dispatch({ type: GET_STORES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getScanDetails = (code, qty, zipCode) => async (dispatch, getState) => {
  const { storeId } = getState().user
  try {
    const res = await api.scan.details(code, storeId, qty, zipCode)
    return dispatch({ type: GET_SCAN_DETAILS, payload: res.data })
  } catch (error) {
    if (error.response?.status === 404) dispatch(resetScanDetails())
    return handleServerError(error)
  }
}

export const resetScanDetails = () => {
  return { type: RESET_SCAN_DETAILS }
}

export const getOrderTracking = (orderNumber, storeId) => async dispatch => {
  try {
    const res = await api.tracking.get(orderNumber, storeId)
    return dispatch({ type: GET_ORDER_TRACKING, payload: res.data })
  } catch (error) {
    if (error.response?.status === 404) dispatch(resetOrderTracking())
    return handleServerError(error)
  }
}

export const resetOrderTracking = () => {
  return { type: RESET_ORDER_TRACKING }
}