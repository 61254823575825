import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Layout as AntLayout } from 'antd'

import { colors } from './constants'

export const Layout = styled(AntLayout)`
  /* background: ${colors.white}; */
`
export const Content = styled(AntLayout.Content)`
  /* padding: 16px; */
  min-height: calc(100vh - 155px);
  padding-bottom: 96px;
`

export const TabsLink = styled(Link)`
  color: unset;
  text-transform: uppercase;
`

export const InputContainer = styled.div`
  padding: 0 32px 64px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 50%)
`