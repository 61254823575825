import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Divider, Input, Modal } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

import {
  getScanDetails,
  resetScanDetails
} from 'src/redux-store/actions/app-actions'
import { saveAnalytics } from 'src/redux-store/actions/analytics-actions'

import Loader from 'src/components/Loader'
import BarcodeScanner from 'src/components/BarcodeScanner'
import Product from 'src/components/Product'
import GridStockDeliveries from 'src/components/GridStockDelivery'
import Footer from 'src/components/Footer'
import {
  EditableFieldsContainer,
  Feedback,
  ProductContainer
} from './styled'
import { Content, InputContainer } from 'src/common/styled'
import barcodeImg from '../../assets/img/barcode.png'
import EditableField from 'src/components/EditableField'
import { SCAN_METHOD_MANUAL, SCAN_METHOD_SCANNER } from 'src/common/constants'

const Home = props => {
  const {
    intl,
    user,
    product,
    getScanDetailsAction,
    resetScanDetails,
    saveAnalytics
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isScanning, setIsScanning] = useState(false)
  const [scanMethod, setScanMethod] = useState('')

  const [qty, setQty] = useState()
  const [zipCode, setZipCode] = useState('')

  useEffect(() => {
    if (!qty && !zipCode) {
      resetData()
    }
  })

  useEffect(() => {
    const input = document.getElementById('zipCode')
    if (input?.value.length === 5) {
      input.blur()
    }
  }, [zipCode])

  const startScanning = useCallback(() => setIsScanning(true), [])
  const stopScanning = useCallback(() => setIsScanning(false), [])

  const getScanDetails = useCallback(
    async (code, method) => {
      stopScanning()
      setIsLoading(true)

      if (method) setScanMethod(method)
      const selectedMethod = method || scanMethod

      await getScanDetailsAction(code, qty || 1, zipCode)
      await saveAnalytics({ qty, zipCode, code, method: selectedMethod })
      setIsLoading(false)
    },
    [getScanDetailsAction, stopScanning, qty, zipCode, scanMethod, saveAnalytics]
  )

  const openManualInsertModal = () => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'manualInsertModal.title' }),
      content: (
        <Input
          size={'large'}
          id={'code'}
          placeholder={intl.formatMessage({
            id: 'manualInsertModal.placeholder'
          })}
        />
      ),
      okText: <CheckOutlined />,
      cancelText: <CloseOutlined />,
      okButtonProps: { size: 'large' },
      cancelButtonProps: { size: 'large' },
      onOk: () => getScanDetails(document.getElementById('code').value, SCAN_METHOD_MANUAL)
    })
  }

  const onScanCallback = useCallback((code) => {
    getScanDetails(code, SCAN_METHOD_SCANNER)
  }, [getScanDetails])

  const onEditFieldCallback = useCallback(() => {
    getScanDetails(product.id)
  }, [getScanDetails, product])

  const resetData = useCallback(() => {
    setIsScanning(false)
    resetScanDetails()
    setQty()
    setZipCode('')
  }, [resetScanDetails])

  // Reset data on store change
  useEffect(() => {
    if (product) {
      resetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.storeId, resetData])

  const renderProduct = useCallback(() => {
    let content = (
      <img
        style={{ display: 'block', margin: '0 auto', width: 200 }}
        src={barcodeImg}
        alt={'barcode'}
      />
    )

    if (isLoading) {
      content = <Loader height={'150px'} />
    }

    if (product) {
      content = <>
        <Product data={product} />
        <Divider />
        <EditableFieldsContainer>
          <EditableField
            name={'qty'}
            data={qty}
            onInputChange={setQty}
            onOk={onEditFieldCallback}
          />
          <Divider type={'vertical'} />
          <EditableField
            name={'zipCode'}
            data={zipCode}
            onInputChange={setZipCode}
            onOk={onEditFieldCallback}
          />
        </EditableFieldsContainer>
      </>
    }

    return <ProductContainer>{content}</ProductContainer>
  }, [isLoading, qty, zipCode, product, onEditFieldCallback])

  const renderStockDeliveries = () => {
    if (isLoading) {
      return <Loader height={'400px'} />
    }

    if (isScanning) {
      return <BarcodeScanner onScan={onScanCallback} />
    }

    if (product) {
      return <GridStockDeliveries product={product} />
    }

    return (
      <>
        <Feedback
          icon={<div></div>}
          subTitle={<FormattedMessage id={'home.text.scanProductBarcode'} />}
        />
        <InputContainer>
          <Input
            size={'large'}
            placeholder={intl.formatMessage({ id: 'input.placeholder.qty' })}
            onChange={e => setQty(e.target.value)}
          />
          <Input
            size={'large'}
            id={'zipCode'}
            placeholder={intl.formatMessage({
              id: 'input.placeholder.zipCode'
            })}
            onChange={e => setZipCode(e.target.value)}
          />
        </InputContainer>
      </>
    )
  }

  return (
    <>
      <Content>
        {renderProduct()}
        {renderStockDeliveries()}
      </Content>
      <Footer
        isScanDisabled={!qty || !zipCode}
        onManualInsertClick={openManualInsertModal}
        onScanButtonClick={startScanning}
        onFinishButtonClick={resetData}
      />
    </>
  )
}

Home.propTypes = {
  getScanDetails: PropTypes.func,
  product: PropTypes.object,
  resetScanDetails: PropTypes.func,
  saveAnalytics: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.user,
  product: state.app.product
})
const mapDispatchToProps = {
  getScanDetailsAction: getScanDetails,
  resetScanDetails,
  saveAnalytics
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
