import { colors } from "./color-constants"

export const SCAN_METHOD_SCANNER = 'SCANNER'
export const SCAN_METHOD_MANUAL = 'MANUAL'

export const EVENT_STATUS_CURRENT = 'CURRENT'
export const EVENT_STATUS_DONE = 'DONE'
export const EVENT_STATUS_ERROR = 'ERROR'
export const EVENT_STATUS_FUTURE = 'FUTURE'

export const eventStatusesMap = {
  [EVENT_STATUS_CURRENT]: {},
  [EVENT_STATUS_DONE]: {
    color: colors.success,
    icon: 'CheckCircleFilled'
  },
  [EVENT_STATUS_ERROR]: {
    color: colors.error,
    icon: 'CloseCircleFilled'
  },
  [EVENT_STATUS_FUTURE]: {
    color: colors.grey3,
  }
}
