import React from 'react'
import PropTypes from 'prop-types'
import { ProductContainer, ProductDescription, ProductTitle } from './styled'

import picture from 'src/assets/img/picture.png'

const Product = props => {
  const { id, description, imgUrl } = props.data
  return (
    <ProductContainer>
      <img src={imgUrl || picture} alt={description} width={104} />
      <span>
        <ProductTitle>{description}</ProductTitle>
        <ProductDescription>{`Ref: ${id}`}</ProductDescription>
      </span>
    </ProductContainer>
  )
}
Product.propTypes = {
  data: PropTypes.object
}
export default Product
