import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  BarcodeOutlined,
  CloseCircleFilled,
  FormOutlined
} from '@ant-design/icons'

import { FooterButton, ScanButton, StyledFooter } from './styled'

const Footer = props => {
  const {
    isScanDisabled,
    onManualInsertClick,
    onScanButtonClick,
    onFinishButtonClick
  } = props
  return (
    <StyledFooter>
      <FooterButton
        type={'text'}
        size={'large'}
        icon={<FormOutlined />}
        disabled={isScanDisabled}
        onClick={onManualInsertClick}>
        <FormattedMessage id={'footer.button.manualInsert'} />
      </FooterButton>
      <ScanButton
        type={'primary'}
        size={'large'}
        shape={'circle'}
        icon={<BarcodeOutlined />}
        disabled={isScanDisabled}
        onClick={onScanButtonClick}
      />
      <FooterButton
        type={'text'}
        size={'large'}
        icon={<CloseCircleFilled />}
        onClick={onFinishButtonClick}>
        <FormattedMessage id={'footer.button.finish'} />
      </FooterButton>
    </StyledFooter>
  )
}
Footer.propTypes = {
  isScanDisabled: PropTypes.bool,
  onFinishButtonClick: PropTypes.func,
  onManualInsertClick: PropTypes.func,
  onScanButtonClick: PropTypes.func
}
export default Footer
