import { SAVE_ANALYTICS } from '../action-types'
import api from 'src/common/api'

export const saveAnalytics = data => async (dispatch, getState) => {
  const { storeId, ldap } = getState().user
  const { product } = getState().app
  const { qty, zipCode, code, method } = data
  const body = {
    storeId,
    ldap,
    quantity: qty,
    zip: zipCode,
    inputCode: code,
    reference: product?.id,
    method,
    found: product ? 1 : 0
  }
  try {
    await api.analytics.save(body)
    return dispatch({ type: SAVE_ANALYTICS })
  } catch (error) {
    console.log(error)
  }
}
