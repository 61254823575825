// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'

export const GET_STORES = 'GET_STORES'
export const GET_SCAN_DETAILS = 'GET_SCAN_DETAILS'
export const RESET_SCAN_DETAILS = 'RESET_SCAN_DETAILS'
export const GET_ORDER_TRACKING = 'GET_ORDER_TRACKING'
export const RESET_ORDER_TRACKING = 'RESET_ORDER_TRACKING'

export const SAVE_ANALYTICS = 'SAVE_ANALYTICS'