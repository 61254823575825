import axios from 'axios'

const { REACT_APP_API_KEY, REACT_APP_API_URL, REACT_APP_ANALYTICS_URL, REACT_APP_ANALYTICS_API_KEY } = process.env

export const BE_API = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'API-Key': REACT_APP_API_KEY
  }
})

export const ANALYTICS_API = axios.create({
  baseURL: REACT_APP_ANALYTICS_URL,
  headers: {
    apiKey: REACT_APP_ANALYTICS_API_KEY
  }
})

const apis = {
  user: {
    authenticate: token =>
      BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  store: {
    list: () => BE_API.get('/stores')
  },
  scan: {
    details: (code, storeId, qty, zipCode) => BE_API.get('/scan', { params: { code, storeId, qty, zipCode } })
  },
  tracking: {
    get: (orderNumber, storeId) => BE_API.get('/tracking', { params: { orderNumber, storeId } })
  },
  analytics: {
    save: body => ANALYTICS_API.post('/analytics', body)
  }
}
export default apis
